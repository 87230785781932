<template>
  <div class="myPoints">
    <div class="myPoints-left">
      <h1>Points Overview</h1>
      <div class="bigBox">
        <div class="myPointsBox">
          <div class="myPointsBox-text">
            <el-button @click="dialogVisible = true">Buy Points</el-button>
            <p>{{pointNum}}</p>
            <p>Available Points</p>
          </div>
          <div class="myPointsBox-text">
            <el-button @click="$router.push('/A_pointCashback')">Points Cashback</el-button>
            <p>{{pointFrozen}}</p>
            <p>Frozen Points</p>
          </div>
        </div>
        <el-button @click="$router.push('/A_scoreDetail')">View Consumption Details</el-button>
      </div>
    </div>
    <div class="myPoints-right">
      <h1>Points FAQ</h1>
      <div class="textBox">
        <div class="font">
          <h3>1.What are points?</h3>
          <p>Points are a kind of virtual currency provided on the Crediscounts platform for everyone to trade. You can get points through tasks in the "Points source" and "Buy points"</p>
        </div>
        <div class="font">
          <h3>2.How do I use points?</h3>
          <p>If you apply for cash withdrawal, Only the part with more than 200 points can be withdrawn
          ,Minimum withdrawal from 200 points. We will pass the review within 24 hours during the cashback application. We will make corresponding adjustments during the holiday time. We will notify you of the specific adjustments in the form of an announcement.</p>
        </div>
        <div class="font">
          <h3>3.What are "Frozen Points"</h3>
          <p>Each buyer applies for a freeze-10 points, the order can be completed after all the return.Deal application does not affect points.</p>
        </div>
        <div class="font">
          <h3>4.About point cashback</h3>
          <p>Buyer can apply for cash back, and fill in a PayPal account (Money will transfer to this paypal account) when applying. After submitting the application, please wait patiently for the administrator to process it. After the administrator agrees, the cashback amount will be credited within 3 ~ 5 days. If the cashback peak occurs, the cashback time will be extended.Please check whether the PayPal account can receive money normally before applying for cashback. If the cashback fails due to account problems, please contact the administrator through online customer service, WeChat public account or email.</p>
        </div>
        <div class="font">
          <p>Please check the terms of credits carefully. If the system detects any behavior such as cheating in credits, you will not be able to withdraw, and the final explanation will belong to the platform.</p>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <el-dialog title
               :visible.sync="dialogVisible"
               width="30%"
               center
               :modal-append-to-body="false">
      <div class="payment">
        Payment is made by
        <span>stripe</span>
      </div>
      <div class="amount">
        <p>Amount:</p>
        <div style="display: flex;justify-content: flex-start;font-size: 20px;align-items: center;">
          $<el-input placeholder="Please enter the amount (not less than $20)"
                    v-model="input"
                    size="small"
                    @input="inputNum()"
                    clearable>
          </el-input>
        </div>
        <div>
          <p>1$ = 10 points</p>
        </div>
        <div class="amount-btn">
          <el-button type="primary"
                     @click="createPay()"
                     class="submitBtn"
                     size="mini">Pay with Alipay</el-button>
          <el-button type="primary"
                     @click="paypalPay()"
                     class="submitBtn"
                     size="mini">Paypal / Credit Card Pay</el-button>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false"
                   size="mini"
                   class="close">close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 引入接口
import {
  userIntegral, // 用户积分
  paypal, // paypal支付
  create // 支付宝支付
} from '../../api/buyerAfter'
export default {
  data () {
    return {
      dialogVisible: false,
      input: '',
      // 积分
      pointNum: '',
      pointFrozen: ''
    }
  },
  created () {
    // 用户积分
    userIntegral({
      userId: localStorage.getItem('buyer-userId')
    }).then(result => {
      if (result.data.code === 200) {
        this.pointNum = result.data.data.integral
        this.pointFrozen = result.data.data.freezing_points
      }
      // ('result =>', result)
    }).catch(err => {
      return err
    })
  },
  methods: {
    // 正则验证
    inputNum () {
      this.input = this.input.replace(/[^\d]|^[0]/g, '')
    },
    // 支付宝购买积分
    createPay () {
      if (this.input === '') {
        this.$message.warning('Please input recharge amount')
      } else if (this.input < 20) {
        this.$message.warning('Minimum recharge amount not less than $20')
      } else {
        // 支付宝
        create({
          total: this.input
        }).then(result => {
          const div = document.createElement('a')
          div.innerHTML = result.data // res.data就是sb支付宝返回给你的form
          document.body.appendChild(div)
          document.forms[0].setAttribute('target', '_blank')
          // document.getElementById('alipaysubmit').submit()
          document.forms[0].submit()
        }).catch(err => {
          return err
        })
      }
    },
    // payapl购买积分
    paypalPay () {
      if (this.input === '') {
        this.$message.warning('Please input recharge amount')
      } else if (this.input < 20) {
        this.$message.warning('Minimum recharge amount not less than $20')
      } else {
        // paypal
        paypal({
          total: this.input
        }).then(result => {
          const div = document.createElement('a')
          div.href = result.data
          div.target = '_new'
          div.click()
          document.body.removeChild(div)
        }).catch(err => {
          return err
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.myPoints {
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  .myPoints-left {
    width: 40%;
    .bigBox {
      margin-top: 15px;
      background-color: white;
      padding: 20px 40px;
      border: 1px solid #c4c4c6;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .myPointsBox {
        margin-bottom: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .myPointsBox-text {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          p {
            width: 100%;
            font-size: 24px;
            margin: 20px 0;
            text-align: center;
            &:nth-child(3) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .myPoints-right {
    width: 50%;
    .textBox {
      margin-top: 15px;
      padding: 30px 20px;
      background-color: white;
      border: 1px solid #c4c4c6;
      border-radius: 5px;
      .font {
        h3 {
          color: #20a8d8;
          margin-bottom: 10px;
          font-size: 16px;
        }
        p {
          margin-bottom: 20px;
          line-height: 20px;
        }
      }
    }
  }
  h1 {
    padding-left: 15px;
    font-size: 28px;
    line-height: 40px;
    border-left: 4px solid #669cbf;
  }
  .el-button {
    font-size: 16px;
    font-weight: bolder;
    color: white;
    background-color: #ff9933;
    border-color: #ff9933;
    border-radius: 5px;
  }
  /deep/ .el-dialog {
    border-radius: 5px;
  }
  /deep/.el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 0px;
  }
  .payment {
    font-size: 16px;
    line-height: 50px;
    text-indent: 1em;
    border-bottom: 1px solid #c4c4c6;
  }
  .amount {
    margin-top: 10px;
    padding: 15px 15px 25px 15px;
    border-bottom: 1px solid #c4c4c6;
    p {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 5px;
    }
    /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: #20a8d8;
      border-color: #20a8d8;
    }
    .el-button {
      background-color: #20a8d8;
      border-color: #20a8d8;
    }
    .amount-btn {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    .el-button {
      background-color: #20a8d8;
      border-color: #20a8d8;
    }
  }
}
</style>
